import axios from 'axios'
import config from './config'

// 获取config请求路径，并配置到axios
axios.defaults.baseURL = config.hostApi()

// 配置请求header参数
axios.interceptors.request.use(function (request) {
  request.headers = {
    'Content-Type': 'application/json'
  }
  // 判断是否有携带token请求
  // if (user.state.tokenHeader) {
  //   request.headers['token'] = user.state.tokenHeader ? user.state.tokenHeader : ''
  //   return request
  // } else {
    return request
  // }
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  if (response.data.code === "0000") {
    return response.data
  } else if (response.data.code === 401) {
    // token失效，请重新登录
    return Promise.reject(response.data.code)
  } else {
    return Promise.reject(response.data.msg)
  }
}, function (error) {
  return Promise.reject(error)
})

export default axios
